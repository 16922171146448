import React from 'react';
import { RouteComponentProps } from 'react-router';

import BankSearch from './bank-search';
import { Page, Row, Alert, SaveProgressButton } from '../../shared';
import * as BankApi from '../../../api/bank-statement';
import canBeOnPage, { BasePageProps } from '../../hoc/can-be-on-page';
import { GenerarteOpenBankingUrlRequest, PreloadRequest } from '../../../api/bank-statement/api models';
import { Bank } from '../../../types/bank-statement';

interface SelectProps extends BasePageProps, RouteComponentProps { }

const Select: React.FC<SelectProps> = ({ appContext }) => {

  const { banks, uiState: { analysisId, consumerId, personalDetails, loanApplicationId, bankProgress }, navigate, executeCommandAndUpdateState, loading, setLoading } = appContext;
  const hasBankIssueAlert = (window as any).hasBankIssueAlert ? true : false;
  const bankIssueAlert = (window as any).bankIssueAlert;
  // dont want this as state so it can't be messed with in inspector
  const forceScreenScraping = bankProgress.some(progress => 
    progress.provider === 'OpenBanking' && 
    ( 
      //Have successfully added an OB bank
      (!progress.isFailed && progress.hasTransactions) || 
      //have unsuccesfully added an OB bank, and that bank can fall back to SC
      (progress.isFailed && banks.some(bank => bank.name === progress.bankName && bank.supportsScreenScraping))
    )
  ); 

  const handleSubmit = (bank: Bank) => {
    const { supportsOpenBanking } = bank;

    if (supportsOpenBanking && !forceScreenScraping) {
      handleOpenBanking(bank);
      return;
    }
    const request: PreloadRequest = { analysisId: analysisId!, consumerId: consumerId!, bankId: bank.id };
    executeCommandAndUpdateState(() => BankApi.preload(request), true)
      .then(r => navigate('BankLogin'));
  }

  const handleOpenBanking = async (bank: Bank) => {
    setLoading(true)
    const request: GenerarteOpenBankingUrlRequest = { analysisId: analysisId!, consumerId: consumerId!, bankID: bank.id, userEmail: personalDetails?.email, userMobile: BankApi.sanitisePhoneNumber(personalDetails?.mobile), bankName: bank.name, loanApplicationId: loanApplicationId };
    const response = await executeCommandAndUpdateState(() => BankApi.generateOpenBankingConsentUrl(request), true)
          .then(response => response.selectedBankConsentUrl ?? "");
    if (response === "") {
      return;
    }
    window.location.href = response?.toString();
  }
  
  return (
    <Page
      title='Your bank'
      lead='To assess your loan we need your last 90 days of statements. This is a responsible lending practice and required by law before we can approve your loan.'
      additionalInfo='Please let us know which financial institution you have a bank account with. There is an option to add other bank accounts later.'
    >
      <Row>
         {hasBankIssueAlert && <Alert variant='info' center message={bankIssueAlert} />}
         {/* TODO: cleanup props going in */}
         <BankSearch banks={banks} banksAlreadyAdded={bankProgress} onSelect={handleSubmit} disabled={loading} forceScreenScraping={forceScreenScraping}/>
        <SaveProgressButton />
      </Row>
    </Page>
  );
}

export default canBeOnPage(Select, 'Bank');