import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { faDollarSign} from '@fortawesome/free-solid-svg-icons';
import { Page, Row, TextBox, Submit, SaveProgressButton, Radio } from '../../shared';
import { validateField } from '../../../utils/validate-field';
import LoanPanel from './loan-panel';
import * as BankApi from '../../../api/bank-statement';
import canBeOnPage, { BasePageProps } from '../../hoc/can-be-on-page';
import { reasons } from '../../../testing/test-data';
import ReasonAccordian from '../../1-loan-application/submit-loan-details/reason-accordian';

interface LoansProps extends BasePageProps, RouteComponentProps {  }
interface State {
  bnplReduceAmount: number
  explanation: string
  loanExplanationIsValid: boolean
  BnplReduceIsValid:boolean
  errorMessage: string
  totalErrorMessage: string
  explanationErrorMessage: string
  reduceAmountErrorMessage: string
  payingOffBnpl: boolean
}

const Loans: React.FC<LoansProps> = ({ appContext }) => {
  const { navigate, executeCommandAndUpdateState, loading, uiState: { analysisId, detectedLoans, hasCentrelinkIncome, detectedBnplLoans, summary } } = appContext;

  const [state, setState] = useState<State>({
    explanation: !detectedLoans ? '' : detectedLoans.explanation || '',
    loanExplanationIsValid: true,
    BnplReduceIsValid: false,
    errorMessage: '',
    bnplReduceAmount: 0,
    totalErrorMessage: '',
    explanationErrorMessage: '',
    reduceAmountErrorMessage: '',
    payingOffBnpl: false
    
  });

  const ChosenReason = summary.fullReason;
  const IsCliApp = ChosenReason === "Line Of Credit - Credit Limit Increase" 
  const AskBnplQuestion = ChosenReason !== "Debt Consolidation / Payout - Buy Now Pay Later" && !IsCliApp;
  
  const validateExplanation = (explanation: string) => {
    const maxLengthValidation = [{ type: 'maxLength', maxLength: 100, message: 'Please use less than 100 characters' }];
    return validateField(explanation, maxLengthValidation);
  }

  const selectedLoanAmount = summary.withdrawAmount ?? summary.amount;
  const validateBnplReduce = (bnplReduceAmount: number) => {
    let total: any[] = [
      { type: 'range', minimum: 50, maximum: selectedLoanAmount, message: `Please enter an amount between $50 and $${selectedLoanAmount}`},
    ];

    if (state.payingOffBnpl)
      total.push({ type: 'required', message: 'Please enter a valid amount' })
    
    return validateField(bnplReduceAmount, total);
  }

  const handleExplanationChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const validation = validateExplanation(value);
    setState((ps) => ({ ...ps, explanation: value, loanExplanationValid: validation.isValid, errorMessage: validation.errorMessage }));
  };

  const handleBnplReduceAmountChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const validation = validateBnplReduce(value);
    setState((ps) => ({ ...ps, bnplReduceAmount: value, BnplReduceIsValid: validation.isValid, reduceAmountErrorMessage: validation.errorMessage}));
  }
  
  function canSubmit(): boolean {
    if (!(state.loanExplanationIsValid)) return false;
    
    if (state.payingOffBnpl && !state.BnplReduceIsValid)
    {
      setState((ps) => ({...ps, reduceAmountErrorMessage: "You have declared that you will be using funds from loan to reduce BNPL debt. Please enter an amount."}))
      return false;
    } 

    return true;
  }
  
  const handleSubmit = () => {
    if (!canSubmit()) return;
    reasonForLoanBnpl();
    const command = () => BankApi.confirmLoans({ analysisId: analysisId!, explanation: state.explanation, bnplReduceAmount: state.bnplReduceAmount, isPayingOffBnpl: state.payingOffBnpl });
    executeCommandAndUpdateState(command, true)
      .then((newState) => {
        navigate(newState.hasCentrelinkIncome ? 'CentrelinkLogin' : 'ThankYou');
      });
  }

  const handleReduceBnplClick = (reduceBnpl: boolean) => {
    if (!reduceBnpl)
    {
      state.bnplReduceAmount = 0;
      state.BnplReduceIsValid = true;
    }
    setState((ps) => ({ ...ps, payingOffBnpl: reduceBnpl, BnplReduceIsValid: false }));
  }

  const selectedYes = state.payingOffBnpl ? true : false;
  const reasonForLoanBnpl= () =>{
    if (!AskBnplQuestion && !IsCliApp){
      state.payingOffBnpl = true
      state.bnplReduceAmount = Number(selectedLoanAmount)
    } 
    return;
  } ;

  const { existingLoans } = detectedLoans;
  const hasLoans = existingLoans && existingLoans.length > 0;
  const lead = hasLoans ? 'We\'ve identified that you have these existing loans.' : 'We were unable to identify that you have any existing loans.';
  const buttonText = hasCentrelinkIncome ? 'Continue' : 'Complete Application';

  const { existingBnpl } = detectedBnplLoans;
  const hasBnpl = existingBnpl && existingBnpl.length > 0;
  
  return (
    <Page
      title='Confirm your loans'
      lead={lead}>
      {hasLoans ? (
        <Row>
          {hasLoans && existingLoans.map((loan, index) => (<LoanPanel {...loan} key={index} />))}
          <div className='text-center'>
            Have your loan repayments changed recently?<br />
            If different from the above, please provide more details.<br /><br />
          </div>
        </Row>
      ) : (
          <Row>
            <div className='text-center'>
              Have you recently applied for another loan?<br />
              If we've missed it, please provide more details.<br /><br />
            </div>
          </Row>
        )}
      <Row>
        <TextBox id='explanation' name='explanation' placeholder='Add details'
          value={state.explanation}
          onChange={handleExplanationChange}
          isValid={state.loanExplanationIsValid}
          errorMessage={state.errorMessage} />
      </Row>
     
      {hasBnpl && AskBnplQuestion && (
        <Row>
          <Row>
              <h2> Buy Now Pay Later</h2>
          </Row>
          <h3>We've identified Buy Now Pay Later debits in your bank statement.</h3>
          <div className='text-center'>
          In addition to your selected reason for loan ({summary.fullReason}), 
            will you be using any of these loan funds to reduce any of your Buy Now Pay Later debt? <br />
          </div>
            <div>
              <Radio
                id='PayingOffBnplIsYes'
                label='Yes'
                value='Yes'
                checked={state.payingOffBnpl === true}
                onChange={() => handleReduceBnplClick(true)}
              />
              <Radio
                id='PayingOffBnplIsNo'
                label='No'
                value='No'
                checked={state.payingOffBnpl === false}
                onChange={() => handleReduceBnplClick(false)}
              /></div>
            {selectedYes  && (
              <div>
                <TextBox id='bnplReduceAmount' name='bnplReduceAmount' 
                  helpText='Please enter the amount you will be putting towards your Buy Now Pay Later debt.'
                  type='number'
                  value={state.bnplReduceAmount}
                  onChange={handleBnplReduceAmountChange}
                  faIconPrefix={faDollarSign}
                  errorMessage={state.reduceAmountErrorMessage}
                  isValid={state.BnplReduceIsValid}
                  />
              </div>
            )}  
        </Row>
      ) }
      <Row>
        <p className="text-center">The above details are true and up to date.</p>
      </Row>
      <Row size='small'>
        <Submit
          data-test='submit-button'
          id='submit'
          text={buttonText}
          onSubmit={handleSubmit}
          disabled={loading}
        />
        <SaveProgressButton />
      </Row>
    </Page>
  );
}


export default canBeOnPage(Loans, 'LoanDeclaration');