import React, {  useRef, useState } from "react";
import { Bank } from "../../../types/bank-statement";
import { BankSearchProps, BankSearchState } from "../definitions";
import { Alert, ButtonWithConfirm } from '../../shared';


const BankSearch = (props: BankSearchProps) => {
  const [state, setState] = useState<BankSearchState>({
    query: "",
    results: props.banks,
    showResults: false,
    cursor: 0,
  });
//TODO destructure all props for use in this component
  const {forceScreenScraping} = props; 
  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [hasAlreadyAddedBank, setHasAlreadyAddedBank] = useState<boolean>(false);
  const [selectedBank, setSelectedBank] = useState<Bank>();

  const bankElements = useRef<any>({});

  const _updateQuery = (event: any) => {
    const { banks } = props;
    const query = event.target.value;
    const results = _filter(banks, query);
    setCanContinue(false);
    setHasAlreadyAddedBank(false);
    setState({
      query: query,
      results: results || banks,
      showResults: results == null ? false : true,
      cursor: 0,
    });
  };

  const _filter = (banks: Bank[], query: string) => {
    if (query === "") return null;
    query = query.trim().toLowerCase();
    let startsWith: Bank[] = [];
    let contains: Bank[] = [];
    let aliased: Bank[] = [];

    // While we only support one OB login per user, we should not show OB only banks after they have added one OB bank account.
    const { banksAlreadyAdded } = props;
    const hasAlreadyUsedOb = banksAlreadyAdded.some((statementProgress) => statementProgress.hasTransactions && !statementProgress.isFailed && statementProgress.provider === "OpenBanking");

    // check the different properties (name, alias) of each bank 
    // to see if they include the query. then add to appropriate array
    banks.forEach((bank) => {

      if(hasAlreadyAddedBank && !bank.supportsScreenScraping) {
          return
      }
      if(hasAlreadyUsedOb && !bank.supportsScreenScraping && bank.supportsOpenBanking){
        return
      }
      if (bank.name.toLowerCase().startsWith(query)) {
        startsWith.push(bank);
      } else if (bank.name.toLowerCase().includes(query)) {
        contains.push(bank);
      } else if (
        bank.aliases &&
        bank.aliases.some((alias) => alias.toLowerCase().includes(query))
      ) {
        aliased.push(bank);
      }
    });
    return startsWith.concat(contains, aliased);
  };

  const _displayAllBanks = (event: any) => {
    const query = event.target.value;

    if (query === "") {
      const { banks } = props;
      setState((previousState) => ({
        ...previousState,
        results: banks,
        showResults: true,
        cursor: 0,
      }));
    }
  };

  const _next = () => {
    var index = Math.min(
      state.results.length - 1,
      state.cursor != null ? state.cursor + 1 : 0
    );
    setState((previousState) => ({ ...previousState, cursor: index }));
    bankElements.current[index].scrollIntoView({ block: 'center', inline: 'nearest' });
  };

  const _previous = () => {
    var index = Math.max(state.cursor - 1, 0);
    setState((previousState) => ({ ...previousState, cursor: index }));
    bankElements.current[index].scrollIntoView({ block: 'center', inline: 'nearest' });
  };

  const _onHover = (index: number) => {
    setState((previousState) => ({ ...previousState, cursor: index }));
  };

  const _onClick = async (item: any) => {
    const { banksAlreadyAdded } = props;
    const bankAlreadyAdded = banksAlreadyAdded.some((statementProgress) => statementProgress.bankName === item.name && statementProgress.hasTransactions && !statementProgress.isFailed);
    const canContinue = !bankAlreadyAdded;

    await setSelectedBank(item);
    await _updateQuery({ target: { value: item.name } });
    await setCanContinue(canContinue);
    await setHasAlreadyAddedBank(bankAlreadyAdded);
    await setState((previousState) => ({ ...previousState, showResults: false }));
  };


  const _handleSubmit = (event: React.FormEvent) => {
    props.onSelect(selectedBank)
  }

  const _handleKeyEvent = (event: any) => {

    switch (event.keyCode) {
      case 40: {
        // Down
        if (state.showResults === false) {
          setState((previousState) => ({
            ...previousState,
            showResults: true,
            cursor: 0,
          }));
        } else {
          _next();
        }
        event.preventDefault();
        event.stopPropagation();
        break;
      }
      case 38: {
        // Up
        _previous();
        event.preventDefault();
        event.stopPropagation();
        break;
      }
      case 9: {
        // Tab
        if (state.showResults === true) {
          event.shiftKey ? _previous() : _next();
          event.preventDefault();
          event.stopPropagation();
        }
        break;
      }
      case 13: {
        // Enter
        if (state.showResults === true) {
          var selected = state.results[state.cursor];
          if (selected) {
            _onClick(selected);
          }
        } else {
          _displayAllBanks(event);
        }
        event.preventDefault();
        event.stopPropagation();
        break;
      }
      case 27: {
        // Escape

        setState((previousState) => ({
          ...previousState,
          showResults: false,
          cursor: 0,
        }));
        break;
      }
    }
  };

  return (
    <>
      <div className="form__row">
        <div className="form__autocomplete">
          <div>
            <label className="form__label">
              Bank name
              <input
                id="bank_search"
                autoFocus
                className="form__input"
                type="search"
                placeholder="Search for your bank by name..."
                value={state.query}
                onChange={_updateQuery}
                onKeyDown={_handleKeyEvent}
                disabled={props.disabled}
              />
            </label>
            {state.showResults && (
              <ul className="menu">
                {state.results.length > 0 &&
                  state.results.map((item, index) => {

                    return (
                      <li
                      key={index}>
                        <a
                          id={`bank_search_item_${item.id}`}
                          className={`${
                            state.cursor === index
                              ? "item item-highlighted"
                              : "item"
                          }`}
                          onClick={() => _onClick(item)}
                          onMouseOver={() => _onHover(index)}
                          tabIndex={0}
                          ref={(item) => bankElements.current[index] = item}
                        >
                          {item.name}
                        </a>
                      </li>
                    );
                  })}
                {state.results.length === 0 && (
                  <li className="item" key={`blank`}>No banks found</li>
                )}
              </ul>
            )}
            {hasAlreadyAddedBank ? <Alert message={`You have already added this bank, please select another or use the progress bar above to continue your application.`} variant={'info'} className={'text-center'}></Alert>
            : selectedBank?.supportsOpenBanking && !forceScreenScraping ? 
            <Alert variant={'info'} className={'text-center'} >
              <p>You will now be taken to our new secure banking provider.</p>
              </Alert>
            : null
          }
          </div>
        </div>
      </div>
      {/* when we commit to OB remove unneccesary components */}
      {selectedBank?.supportsOpenBanking && !forceScreenScraping ? <ContinueButtonOpenBanking canContinue={canContinue} _handleSubmit={_handleSubmit}/> : 
     <ContinueButtonBank canContinue={canContinue} _handleSubmit={_handleSubmit}  /> }
    </>
  );
};

type ContinueButtonProps = {
  canContinue: boolean,
  _handleSubmit: any,
  openBankingUrl?: string
}

const ContinueButtonBank = (props: ContinueButtonProps) => {
  const {canContinue, _handleSubmit} = props
  return(
    <button
    type="button"
    className="button button-primary button--full"
    disabled={!canContinue}
    onClick={_handleSubmit}
    >
    Continue
  </button>
  )
}

const ContinueButtonOpenBanking = (props: ContinueButtonProps) => {
  const modalTitleText = `Ensure all accounts are selected`
  const modalBodyText = `In order for us to complete a full assessment, you need to select all bank accounts you hold with this financial institution when prompted.`
  const {canContinue, _handleSubmit} = props
  return(
    <ButtonWithConfirm disabled={!canContinue} buttonVariant="primary" text='Continue' modalConfirmText="Continue" modalTitle={modalTitleText} modalBody={modalBodyText} isExternal={true} onConfirm={_handleSubmit}  />
  )
}
export default BankSearch;
